import { motion } from "framer-motion";
import React, { useContext } from "react";
import { UserContext } from "../context/User";
import Button from "./Button";
import Slider from "./Slider";
import { gaEvent, gtagEvent } from "../utils";

const Start = () => {
  const { setCurrentStep } = useContext(UserContext);

  return (
    <div className="pt-10 md:py-10">
      <div
        style={{ maxWidth: 1200 }}
        className="flex flex-col-reverse md:flex-row md:m-auto flex-wrap md:flex-nowrap w-100 items-center mt-12 md:mt-32"
      >
        <motion.div
          initial={{ x: -50 }}
          animate={{ x: 0 }}
          className="w-2/3 m-auto pb-12 md:pb-0 md:w-3/5"
        >
          <Slider />
        </motion.div>
        <div className=" md:w-2/5 flex flex-wrap pb-12 md:pl-12 uppercase font-bold">
          <motion.h1
            initial={{ x: 50 }}
            animate={{ x: 0 }}
            className="text-white m-auto md:m-0 text-4xl text-center md:text-left md:text-5xl py-8 select-none "
          >
            Which video
            <br />
            display is right
            <br />
            <span className="text-teal font-bold ">for you?</span>
          </motion.h1>
          <motion.div
            className="w-full justify-center md:justify-start flex"
            initial={{ y: 40 }}
            animate={{ y: 0 }}
            onClick={() => {
              setCurrentStep(0);
              gaEvent("submit", "iris_quiz_engagement", "start");
            }}
          >
            <Button type="primary" title="Get Started ›" />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Start;
