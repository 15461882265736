import { render } from "@testing-library/react";
import { motion } from "framer-motion";
import _, { pick } from "lodash";
import React, { useContext, useState } from "react";
import { UserContext } from "../context/User";
import { INITIAL_SCORE } from "../initial_data";
import { iconBack } from "../icons";

const SeriesSelector = () => {
  const [panels, setPanels] = useState(INITIAL_SCORE);
  const { setCurrentStep, setPick, currentStep } = useContext(UserContext);

  const renderSeries = () => {
    return _.map(panels, (series, index) => {
      return (
        <motion.div
          key={index}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: index * 0.1 }}
          onClick={() => {
            setPick(series);
            setCurrentStep("results");
          }}
        >
          <img
            className="hover:scale-110 transition duration-300 cursor-pointer"
            style={{ height: 170 }}
            src={series.logo}
          />

          <p
            style={{ color: series.color }}
            className="font-bold text-white mb-12"
          >
            {series.subheadline}
          </p>
        </motion.div>
      );
    });
  };
  return (
    <div className="">
      <div>
        <img
          style={{ width: 12 }}
          className="cursor-pointer absolute top-4 left-4"
          onClick={(e) => setCurrentStep("results")}
          src={iconBack}
          alt=""
        />
      </div>
      <h1 className="text-white text-xl md:text-3xl mt-3 mb-8">
        Select an IRiS® Video Wall Series
      </h1>
      <div className="flex flex-wrap justify-center">{renderSeries()}</div>
    </div>
  );
};

export default SeriesSelector;
