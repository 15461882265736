export const webUrl = "https://iris-bca992.webflow.io";
export function handleChoiceDelay() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, 500);
  });
}
export const validateEmail = (email) => {
  var filter = // eslint-disable-next-line
    /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
  return String(email).search(filter) !== -1;
};

export const gaEvent = function (action, label, value) {
  window.gtag("event", action, {
    [label]: value,
  });
};
export const gtagEvent = function (id, value, url) {
  var callback = function () {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };

  //google analytics event

  window.gtag("event", "conversion", {
    send_to: id,
    value: value,
    currency: "USD",
    event_callback: callback,
  });

  return false;
};
