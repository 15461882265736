import React, { useState } from "react";
import { iconBlob } from "../icons";
import { useInterval } from "../hooks/useInterval";
import { motion } from "framer-motion";

const Slider = () => {
  const [active, setActive] = useState(3);
  useInterval(() => {
    if (active < 4) {
      setActive(active + 1);
    } else {
      setActive(0);
    }
  }, 90000000);

  const renderSlide = () => {
    switch (active) {
      case 0:
        return (
          <motion.div key={1} initial={{ y: 10 }} animate={{ y: 0 }}>
            <img
              alt="Iris panel"
              stye={{ maxWidth: 200 }}
              className="select-none  m-auto"
              src={
                "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/64186a570d52a32ed7e95879_products%252F3187%252Fimages%252FICON-26-featured_1440x1440.webp"
              }
            />
          </motion.div>
        );
      case 1:
        return (
          <motion.div key={2} initial={{ y: 10 }} animate={{ y: 0 }}>
            <img
              alt="Iris panel"
              stye={{ maxWidth: 200 }}
              className="select-none  m-auto"
              src={
                "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/6418ac56f2ff9133d783833d_IRiS_InSite_18_0001_1440x1440.webp"
              }
            />
          </motion.div>
        );
      case 2:
        return (
          <motion.div key={3} initial={{ y: 10 }} animate={{ y: 0 }}>
            <img
              alt="Iris panel"
              stye={{ maxWidth: 200 }}
              className="select-none  m-auto"
              src={
                "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/6418adaef2502aff449ed799_gt3-rear_1440x1440.webp"
              }
            />
          </motion.div>
        );
      case 3:
        return (
          <motion.div key={4} initial={{ y: 10 }} animate={{ y: 0 }}>
            <img
              alt="Iris panel"
              stye={{ maxWidth: 200 }}
              className="select-none  m-auto"
              src={
                "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/64186d923604b310d25fdf3a_products%252F3216%252Fimages%252Finsignia_ip_rear_1440x1440.webp"
              }
            />
          </motion.div>
        );
      case 4:
        return (
          <motion.div key={5} initial={{ y: 10 }} animate={{ y: 0 }}>
            <img
              alt="Iris panel"
              stye={{ maxWidth: 200 }}
              className="select-none  m-auto"
              src={
                "https://global-uploads.webflow.com/6400c151037d7a4efc7e9278/6413408cac88daf28f3747b9_iris_inspire_white%201.png"
              }
            />
          </motion.div>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <div className="relative flex justify-center items-center">
        <div style={{ width: "100%" }} className="z-10 relative">
          {renderSlide()}
        </div>
        <img
          className="absolute top-50 z-0 select-none"
          style={{ width: 600 }}
          src={iconBlob}
          alt=""
        />
      </div>
    </>
  );
};

export default Slider;
