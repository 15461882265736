import _ from "lodash";
import React, { useState, createContext } from "react";
import { INITIAL_STATE, INITIAL_SCORE } from "../initial_data";
import axios from "axios";
//remove dev

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [currentStep, setCurrentStep] = useState("start");
  const [userSelection, setUserSelection] = useState(INITIAL_STATE);
  const [bulkData, setBulkData] = useState([]);
  const [score, setScore] = useState(INITIAL_SCORE);
  const [pick, setPick] = useState(null);

  const generateNewData = () => {
    const data = [];
    for (let i = 0; i < 8; i++) {
      const uid = new Date().getTime();
      data.push({
        id: uid,
        selected: null,
      });
    }
    setUserSelection(data);
    setCurrentStep("start");
  };
  const resetForm = () => {
    generateNewData();
    setScore(INITIAL_SCORE);
    setPick([]);
  };

  const modifySelection = (id, value) => {
    const selection = [...userSelection];
    selection[id].selected = value;
    setUserSelection(selection);
  };

  const scoreSetter = (series, value) => {
    const scoreDupe = [...score];
    const index = _.findIndex(scoreDupe, { series: series });

    scoreDupe[index].score = scoreDupe[index].score + value;
    setScore(scoreDupe);
  };

  //select your application
  function question1() {
    switch (userSelection[0].selected) {
      case 0:
        scoreSetter("inspire", +3);
        return;
      case 1:
        scoreSetter("gt", +3);
        scoreSetter("icon", +1);
        return;
      case 2:
        scoreSetter("icon", +3);
        scoreSetter("gt", +2);
        return;
      case 3:
        scoreSetter("insite", +2);
        scoreSetter("insignia", +2);
        scoreSetter("icon", +1);
        return;
      case 4:
        scoreSetter("icon", +3);
        return;
      case 5:
        return;
      default:
        return;
    }
  }

  //primary use
  function question2() {
    switch (userSelection[1].selected) {
      case 0:
        scoreSetter("insite", +2);
        scoreSetter("inspire", +2);
        scoreSetter("icon", +1);
        scoreSetter("gt", +1);
        scoreSetter("insignia", +2);
        return;
      case 1:
        scoreSetter("gt", +2);
        scoreSetter("icon", +2);
        scoreSetter("insignia", +2);
        scoreSetter("insite", -10);
        scoreSetter("inspire", -10);
        return;
      case 2:
        scoreSetter("insite", -10);
        scoreSetter("inspire", -10);
        return;
      default:
        return;
    }
  }

  //perma installed?
  function question3() {
    switch (userSelection[2].selected) {
      case 0:
        scoreSetter("insite", +5);
        scoreSetter("inspire", +5);
        return;
      case 1:
        scoreSetter("gt", +2);
        scoreSetter("icon", +2);
        scoreSetter("insignia", +1);
        return;
      case 2:
        return;
      default:
        return;
    }
  }

  //mounting
  function question4() {
    switch (userSelection[3].selected) {
      case 0:
        scoreSetter("insite", -5);
        scoreSetter("inspire", -5);
        return;

      case 1:
        scoreSetter("insite", +3);
        scoreSetter("inspire", +3);
        return;
      case 3:
        scoreSetter("gt", +2);
        scoreSetter("icon", +2);
        scoreSetter("insignia", +2);
        return;
      case 4:
        return;
      default:
        return;
    }
  }

  //tour carts
  function question5() {
    switch (userSelection[5].selected) {
      case 0:
        scoreSetter("gt", +3);
      case 1:
        return;
      case 3:
        return;

      default:
        return;
    }
  }

  const updateHubspot = (winner) => {
    const obj = {
      email: userSelection[6].selected,
      dynamic_shopify_form_referrer: `IRiS Rec: ${winner}`,
    };

    axios.post("https://api.blizzard.lighting/update-contact", obj, {
      timeout: 10000,
    });
  };

  const determineProduct = () => {
    const winner = _.maxBy(score, "score");
    const desiredPixelPitch = userSelection[4].selected;
    let panelPitches = [];
    winner.panels.map((panel) => {
      panelPitches.push(panel.pixelPitch);
    });
    const pixelPitch = panelPitches.reduce((prev, curr) =>
      Math.abs(curr - desiredPixelPitch) < Math.abs(prev - desiredPixelPitch)
        ? curr
        : prev
    );

    let selectedPanelData = { ...winner, pitch: pixelPitch };

    setPick({ ...winner, pitch: pixelPitch });

    let selectedPanel;

    if (selectedPanelData.pixelPitch) {
      selectedPanel = _.find(selectedPanelData.panels, {
        pixelPitch: selectedPanelData.pitch,
      });
    } else {
      selectedPanel = selectedPanelData.panels[0];
    }

    updateHubspot(selectedPanel.name);
  };

  const calcResults = async () => {
    await question1();
    await question2();
    await question3();
    await question4();
    await question5();

    determineProduct();
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        currentStep,
        setCurrentStep,
        userSelection,
        setUserSelection,
        modifySelection,
        resetForm,
        bulkData,
        calcResults,
        setBulkData,
        score,
        pick,
        setPick,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
