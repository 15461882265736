import React from "react";

const Button = ({
  type,
  title,
  icon,
  disabled,
  iconLocation,
  customStyles,
}) => {
  let styles =
    type === "primary"
      ? "bg-blue text-white hover:bg-blue focus:bg-blue active:bg-blue"
      : "border border-textBlue text-white hover:bg-blue focus:bg-blue active:bg-blue hover:text-white";
  return (
    <div className={`flex space-x-2 justify-center ${customStyles}`}>
      <div>
        <button
          disabled={disabled}
          type="button"
          className={
            "select-none disabled:bg-gray-400 disabled:text-gray-500 flex items-center px-6 pt-2.5 pb-2 text-xl leading-normal shadow-md hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out flex align-center " +
            styles
          }
        >
          {iconLocation === "start" && icon}

          {title}

          {iconLocation === "end" && icon}
        </button>
      </div>
    </div>
  );
};

export default Button;
