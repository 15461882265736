import React, { useContext, useState } from "react";
import { UserContext } from "../context/User";
import _ from "lodash";

import { motion } from "framer-motion";
import Button from "./Button";
import Blob from "./Blob";
import { webUrl } from "../utils";

const Results = () => {
  const {
    pick,
    bulkData,
    userSelection,
    resetForm,
    setBulkData,
    setCurrentStep,
    setPick,
  } = useContext(UserContext);

  const [openDialog, setOpenDialog] = useState(false);
  React.useEffect(() => {
    setBulkData([...bulkData, userSelection]);
  }, []);

  const renderSpecs = () => {
    let selectedPanel;
    if (pick.pitch) {
      selectedPanel = _.find(pick.panels, { pixelPitch: pick.pitch });
    } else {
      selectedPanel = pick.panels[0];
    }

    return (
      <motion.div
        transition={{ delay: 0.3 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="lg:px-20 w-full mb-12"
      >
        <div
          className="border-b-4 py-3 relative"
          style={{ borderColor: pick.color }}
        >
          <div className="flex justify-between items-baseline text-white font-bold text-xl">
            <h3>{selectedPanel.name}</h3>
            <div className="flex align-center">
              <p
                className="text-sm opacity-40 cursor-pointer"
                onClick={() => setOpenDialog(true)}
              >
                Similar Panels
              </p>
              <i className="fa-solid fa-chevron-down pl-3"></i>
            </div>
            {openDialog && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.08,
                }}
                className="absolute rounded-lg w-48 right-0 top-8 py-4"
              >
                {pick.panels.map((p) => {
                  return (
                    <div
                      style={{ borderColor: "#252238", background: "#0c0921" }}
                      onClick={() => {
                        setOpenDialog(false);
                        setPick({ ...pick, pitch: p.pixelPitch });
                      }}
                      className="py-4 text-sm border"
                    >
                      <p className="cursor-pointer">{p.name}</p>
                    </div>
                  );
                })}
              </motion.div>
            )}
          </div>
        </div>
        <div className="flex flex-wrap justify-between text-white text-md">
          <div
            style={{ background: "rgb(32 32 32)" }}
            className="flex px-4 w-full justify-between"
          >
            <h4 className="py-3">LED Configuration</h4>
            <h4 className="py-3">{selectedPanel.ledConfig}</h4>
          </div>
          <div className="flex px-4 w-full justify-between">
            <h4 className="py-3">Refresh Rate</h4>
            <h4 className="py-3">{selectedPanel.refreshRate}</h4>
          </div>
          <div
            style={{ background: "rgb(32 32 32)" }}
            className="flex px-4 w-full justify-between"
          >
            <h4 className="py-3">Brightness</h4>
            <h4 className="py-3">{selectedPanel.brightness}</h4>
          </div>
          <div className="flex px-4 w-full justify-between">
            <h4 className="py-3">Panel Size</h4>
            <h4 className="py-3">{selectedPanel.dimensions}</h4>
          </div>
          <div
            style={{ background: "rgb(32 32 32)" }}
            className="flex px-4 w-full justify-between"
          >
            <h4 className="py-3">Pixel Pitch</h4>
            <h4 className="py-3">{selectedPanel.pixelPitch} MM</h4>
          </div>
          <div className="flex px-4 w-full justify-between">
            <h4 className="py-3">Environment</h4>
            <h4 className="py-3">{selectedPanel.environment}</h4>
          </div>
        </div>
      </motion.div>
    );
  };

  const renderPanel = () => {
    let selectedPanel;
    if (pick.pitch) {
      selectedPanel = _.find(pick.panels, { pixelPitch: pick.pitch });
    } else {
      selectedPanel = pick.panels[0];
    }

    return (
      <motion.img
        transition={{ delay: 0.25 }}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        style={{ maxHeight: 700 }}
        src={selectedPanel.image}
        alt=""
      />
    );
  };

  if (pick) {
    return (
      <div className="">
        <div className="flex flex-wrap flex-col lg:flex-row items-center max-w-screen-xl m-auto">
          <div className="w-11/12 m-auto lg:w-1/2">
            <motion.div
              transition={{ delay: 0.25 }}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
            >
              <h1 className="text-white text-2xl mt-4 lg:mt-0">
                We recommend:
              </h1>
              <img src={pick.logo} alt={pick.panel} />
              <div
                className="p-5 mb-4 table m-auto"
                style={{ background: pick.color }}
              >
                <h2 className="text-white text-2xl ">{pick.subheadline}</h2>
              </div>
            </motion.div>

            {renderSpecs()}

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.25 }}
            >
              <div className="flex justify-center flex-wrap">
                <div className="mb-3 hover:text-white">
                  <a
                    className=""
                    target="_blank"
                    href={`${webUrl}/ledvideo/${pick.handle}`}
                  >
                    <Button title="View Full Specs" />
                  </a>
                </div>

                <div
                  className="ml-3"
                  onClick={() => setCurrentStep("selector")}
                >
                  <Button
                    iconLocation="end"
                    icon={
                      <i className="ml-4 text-white fa-solid fa-angles-right"></i>
                    }
                    type="primary"
                    title="View More"
                  />
                </div>
                <div
                  onClick={() => resetForm()}
                  className="cursor-pointer w-full pt-3 text-white opacity-50 underline font-normal"
                >
                  Start Over
                </div>
              </div>
              <div
                className="border-2 cursor-pointer mt-12 p-4"
                style={{ borderColor: pick.color }}
              >
                <div className="flex justify-between items-center">
                  <i
                    style={{ color: pick.color }}
                    className="text-4xl w-2/12 fa-solid fa-circle-envelope"
                  ></i>
                  <a
                    href={`${webUrl}/contact`}
                    className="w-11/12 tcontactext-left text-lg text-white"
                  >
                    Have questions about our recommendation?
                    <br />
                    Click here to contact one of our LED video experts.
                  </a>
                </div>
              </div>
            </motion.div>
          </div>

          <div className="w-1/2 relative">
            <motion.div
              className="relative flex justify-center items-center"
              transition={{ delay: 0.25 }}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
            >
              <div className="z-10 relative my-24">{renderPanel()}</div>
              <div
                style={{ width: 500 }}
                className="hidden lg:block absolute top-50 left-0 right-0 m-auto z-0 select-none"
              >
                <Blob fill={pick.color} />
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    );
  } else return <p>Nope</p>;
};

export default Results;
