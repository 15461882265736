import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga";

ReactGA.initialize("UA-16655377-1", {
  titleCase: false,
});
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.createRoot(document.getElementById("iris-suggester")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
