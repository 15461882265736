import { motion } from "framer-motion";
import React, { useContext } from "react";
import { UserContext } from "../context/User";
import Button from "./Button";

const ThankYou = () => {
  const { resetForm, userSelection, setBulkData, bulkData } =
    useContext(UserContext);

  React.useEffect(() => {
    setBulkData([...bulkData, userSelection]);
  }, []);

  return (
    <div className="">
      <motion.h2
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="mb-5 text-5xl text-white"
      >
        Thank You
      </motion.h2>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.25 }}
      >
        <div className="flex justify-center">
          <div className="hover:text-white" onClick={() => resetForm()}>
            <Button
              icon={<i className="mr-4 text-white fa-solid fa-angles-left"></i>}
              title="Start Over"
            />
          </div>

          <div className="ml-3">
            <Button type="primary" title="Build this Wall" />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ThankYou;
