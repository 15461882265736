import React, { useContext } from "react";
import { motion } from "framer-motion";
import RadioGroup from "./RadioGroup";
import { UserContext } from "../context/User";
import Range from "./Range";
import OptIn from "./OptIn";
import { iconBack } from "../icons";

const Step = ({ step }) => {
  const { currentStep, setCurrentStep } = useContext(UserContext);
  const renderOptions = () => {
    switch (step.params.type) {
      case "optin":
        return <OptIn />;
      case "radio":
        return (
          <RadioGroup options={step.params.options} columns={step.columns} />
        );
      case "slider":
        return <Range id={step.id} max={step.params.max} />;
      default:
        return null;
    }
  };

  const renderNav = () => {
    let isFirst = step.id === 0;

    return (
      <div className="w-full py-4 items-center flex md:block">
        {!isFirst && (
          <span
            dangerouslySetInnerHTML={{ __html: iconBack }}
            className="w-4 md:w-6 cursor-pointer absolute top-3 left-8"
            onClick={(e) => setCurrentStep(currentStep - 1)}
            src={iconBack}
            alt=""
          />
        )}
        <h4 className="w-full select-none md:pl-0 md:pt-3  text-center text-white font-bold uppercase tracking-wide">
          Step {currentStep + 1} of 8
        </h4>
      </div>
    );
  };

  return (
    <div className="w-11/12 m-auto md:w-full">
      <div className=" top-0 w-full">{renderNav()}</div>
      <motion.div initial={{ y: 10 }} animate={{ y: 0 }}>
        <h2 className="uppercase font-bold select-none md:px-32 p-4 text-4xl text-white">
          {step.name}
        </h2>
        <p className="select-none text-textBlue text-xl pb-12">
          {step.subtext}
        </p>
      </motion.div>
      {renderOptions()}
    </div>
  );
};

export default Step;
