import React, { useState } from "react";

const Blob = ({ fill }) => {
  return (
    <svg
      id="f71d66b1-cff1-4988-b3bb-61f0fbca9c37"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1164.42 1149.89"
    >
      <defs></defs>
      <path
        style={{ fill: fill }}
        class="b6511a2f-903f-4033-97fc-572ccb9d3288"
        d="M933.6,149.06c-37.33-17.66-76.47-32-115.64-45.15C702.3,65,583.91,33.42,463.36,14.08c-73.14-11.71-143-24.43-214.73-.66-57,18.87-104.86,60.76-139.13,109.17C73.61,173.3,52,232.09,38,292.21,23.83,353.27,14.92,415.77,7.17,478c-6.95,55.81-10.76,113-2.4,168.85,9,60,34,115.09,71.63,162.56s85,86.81,132.33,124.23c101,79.8,206.18,163.81,332.34,199,58.87,16.44,120.78,22.68,181.3,11.92,58.5-10.4,113.34-35.17,162.14-68.68,108.49-74.5,179.82-193.09,227.91-313.17,44.78-111.84,68.27-237.05,39.15-356-21.1-86.11-63.75-158.1-136.57-210.43C989.43,177.93,962,162.52,933.6,149.06Z"
      />
    </svg>
  );
};

export default Blob;
