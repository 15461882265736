import _ from "lodash";
import React, { useContext } from "react";
import { UserContext } from "../context/User";
import { steps } from "../data";
import Results from "./Results";
import SeriesSelector from "./SeriesSelector";
import Start from "./Start";
import Step from "./Step";
import ThankYou from "./ThankYou";

const View = () => {
  const { currentStep } = useContext(UserContext);

  const renderSteps = () => {
    if (currentStep === "start") {
      return <Start />;
    }
    if (currentStep === "selector") {
      return <SeriesSelector />;
    }
    if (currentStep === "end") {
      return <ThankYou />;
    }
    if (currentStep === "results") {
      return <Results />;
    } else {
      return _.map(steps, (step) => {
        if (step.id === currentStep) {
          return <Step key={step.id} step={step} />;
        }
      });
    }
  };
  return <div className="w-full">{renderSteps()}</div>;
};

export default View;
