import React, { useContext } from "react";
import { motion } from "framer-motion";
import { UserContext } from "../context/User";
import { handleChoiceDelay } from "../utils";

const RadioGroup = ({ options }) => {
  const { setCurrentStep, currentStep, userSelection, modifySelection } =
    useContext(UserContext);

  const handleSelect = (id) => {
    modifySelection(currentStep, id);

    handleChoiceDelay().then(() => {
      setCurrentStep(currentStep + 1);
    });
  };

  return (
    <div
      style={{ maxWidth: 600 }}
      className="grid grid-cols-2 md:grid-cols-3 gap-12  m-auto justify-center"
    >
      {options.map((option, index) => {
        return (
          <motion.button
            key={index}
            onClick={() => handleSelect(index)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={`rounded py-5 flex focus:bg-blue flex-wrap justify-center cursor-pointer hover:scale-110 transition duration-300 ${
              userSelection[currentStep].selected === index ? "bg-blue" : null
            }`}
          >
            <div className="w-full">
              <span
                style={{ height: 125 }}
                className="m-auto"
                dangerouslySetInnerHTML={{ __html: option.icon }}
              />
              <img src={option.icon} alt="" />
            </div>
            <p className="text-lg md:text-xl text-white border-bg-blue md:p-3">
              {option.title}
            </p>
          </motion.button>
        );
      })}
    </div>
  );
};

export default RadioGroup;
