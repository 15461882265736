import "./App.css";
import { UserProvider } from "./context/User";
import View from "./components/View";
import Footer from "./components/Footer";
import ReactGA from "react-ga";

ReactGA.initialize("UA-16655377-1");

function App() {
  return (
    <div className="App bg-darkBlue min-h-screen">
      <UserProvider>
        <div
          className="md:h-screen flex md:items-center justify-center"
          style={{ minHeight: 760 }}
        >
          <View />
        </div>
      </UserProvider>
    </div>
  );
}

export default App;
