import {
  iconStage,
  iconPraise,
  iconVan,
  iconCorporate,
  iconQuestion,
  iconIndoor,
  iconIndoorOutdoor,
  iconOutdoor,
  iconCheck,
  iconX,
  iconXr,
  iconMounted,
  iconHung,
  iconGround,
} from "./icons";

export const steps = [
  {
    id: 0,
    name: "Select your application",
    subtext: "Which best describes the purpose of your wall?",
    params: {
      type: "radio",
      options: [
        {
          title: "House of Worship",
          icon: iconPraise,
        },
        {
          title: "Pro Touring",
          icon: iconVan,
        },
        {
          title: "Stage / Rental",
          icon: iconStage,
        },
        {
          title: "Corporate / Hospitality",
          icon: iconCorporate,
        },
        {
          title: "Virtual Production",
          icon: iconXr,
        },
        {
          title: "Other",
          icon: iconQuestion,
        },
      ],
    },
  },
  {
    id: 1,
    name: "Where will your wall be located?",
    subtext: "",
    params: {
      type: "radio",
      options: [
        {
          title: "Indoor",
          icon: iconIndoor,
        },
        {
          title: "Outdoor",
          icon: iconOutdoor,
        },
        {
          title: "Both",
          icon: iconIndoorOutdoor,
        },
      ],
    },
  },
  {
    id: 2,
    name: "Will your wall be permanently installed?",
    subtext: "",
    params: {
      type: "radio",
      options: [
        {
          title: "Yes",
          icon: iconCheck,
        },
        {
          title: "No",
          icon: iconX,
        },
        {
          title: "Unsure",
          icon: iconQuestion,
        },
      ],
    },
  },
  {
    id: 3,
    columns: "4",
    name: "How will your panels be anchored?",
    subtext: "Choose the one that best fits",
    params: {
      type: "radio",
      options: [
        {
          title: "Flown/Hung",
          icon: iconHung,
        },
        {
          title: "Wall Mounted",
          icon: iconMounted,
        },
        {
          title: "Ground Stacked",
          icon: iconGround,
        },
      ],
    },
  },

  {
    id: 4,
    name: "Viewing Distance",
    subtext: "How far away will the first viewer of the wall be?",
    params: {
      type: "slider",
      max: 10,
    },
  },
  {
    id: 5,
    name: "Tour Carts",
    subtext: "Will you need road cases or tour carts for transport?",
    params: {
      type: "radio",
      options: [
        {
          title: "Yes",
          icon: iconCheck,
        },
        {
          title: "No",
          icon: iconX,
        },
        {
          title: "Unsure",
          icon: iconQuestion,
        },
      ],
    },
  },
  {
    id: 6,
    name: "See your recommendation",
    subtext: "Please enter your email address to view results",
    params: {
      type: "optin",
    },
  },
];
