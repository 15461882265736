import { motion } from "framer-motion";
import React, { useContext, useState } from "react";
import { UserContext } from "../context/User";
import { iconPanel } from "../icons";
import { handleChoiceDelay } from "../utils";
import Button from "./Button";

const Range = ({ max, id }) => {
  const [meters, setMeters] = useState(3);
  const { setCurrentStep, currentStep, userSelection, modifySelection } =
    useContext(UserContext);
  // const renderRangeLabels = () => {
  //   let labels = [];
  //   for (let i = 0; i < 10; i++) {
  //     labels.push(
  //       <li key={i} className=" text-white">
  //         {i + 1}
  //       </li>
  //     );
  //   }
  //   return labels;
  // };

  const handleSelect = (e) => {
    handleChoiceDelay().then(() => {
      setCurrentStep(currentStep + 1);
      modifySelection(id, parseFloat(meters));
    });
  };

  const renderMeters = () => {
    if (userSelection[id].selected) {
      return userSelection[id].selected;
    } else {
      return 3;
    }
  };

  return (
    <div
      className="m-auto flex items-center flex-wrap justify-between"
      style={{ maxWidth: 600 }}
    >
      <span
        className="w-1/12"
        dangerouslySetInnerHTML={{ __html: iconPanel }}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative pt-1 w-9/12"
      >
        <input
          type="range"
          min={0}
          value={userSelection[id].selected ? userSelection[id].selected : 3}
          max={max}
          step="1"
          // onMouseUp={(e) => handleSelect(e)}
          // onTouchEnd={(e) => handleSelect(e)}
          onChange={(e) => {
            modifySelection(id, e.target.value);
            setMeters(e.target.value);
          }}
          className="form-range w-full h-6 p-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none"
          id="customRange1"
        />
        {/* <ul className="range-labels">{renderRangeLabels()}</ul> */}
      </motion.div>
      <motion.div>
        <h1 className="text-white text-6xl">{renderMeters()}</h1>
        <p className="text-textBlue">meters</p>
      </motion.div>
      <motion.div className="m-auto" onClick={(e) => handleSelect(e)}>
        <Button title="Next" type="primary" />
      </motion.div>
    </div>
  );
};

export default Range;
