import { motion } from "framer-motion";
import React, { useContext, useState } from "react";
import { ReactGAImplementation } from "react-ga";
import { UserContext } from "../context/User";
import { gaEvent, gtagEvent, handleChoiceDelay, validateEmail } from "../utils";
import Button from "./Button";
import Input from "./Input";
import ReactGA from "react-ga";

const OptIn = () => {
  const { setCurrentStep, modifySelection, calcResults } =
    useContext(UserContext);
  const [email, setEmail] = useState("");
  const [emailedValidated, setEmailedValidated] = useState(false);

  React.useEffect(() => {
    setEmailedValidated(!validateEmail(email));
  }, [email]);

  const handleGA = () => {
    gaEvent("submit", "iris_quiz_engagement", "finish");
  };

  return (
    <div className="w-80 m-auto">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <Input
          label="Email"
          value={email}
          onChange={(e) => setEmail(e)}
          placeholder={"Enter your email"}
        />
        <div
          onClick={() => {
            handleChoiceDelay().then(() => {
              handleGA();
              calcResults();
              modifySelection(6, email);
              setCurrentStep("results");
            });
          }}
        >
          <Button disabled={emailedValidated} type="primary" title="Continue" />
        </div>
      </motion.div>
    </div>
  );
};

export default OptIn;
