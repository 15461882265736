export const INITIAL_SCORE = [
  {
    series: "gt",
    subheadline: "IP65-Rated Pro Touring Video Panels",
    score: 0,
    handle: "iris-gt3-xl",
    color: "#c83c27",
    logo: "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/641a024579c4362358c24b7a_logo-gt.png",
    panels: [
      {
        name: "GT3™ XL",
        pixelPitch: 3.9,
        ledConfig: "SMD 1921",
        environment: "Indoor / Outdoor",
        refreshRate: "≥3,840 Hz",
        brightness: "4,500 NITS",
        dimensions: "500x1000 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/6418adb67440cc7d5542d702_gt3-xl_1440x1440.webp",
      },
      {
        name: "GT3™",
        pixelPitch: 3.9,
        ledConfig: "SMD 1921",
        environment: "Indoor / Outdoor",
        refreshRate: "≥3,840 Hz",
        brightness: "4,500 NITS",
        dimensions: "500x500 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/6418adaef2502aff449ed799_gt3-rear_1440x1440.webp",
      },
    ],
  },
  {
    series: "icon",
    handle: "iris-icon-ip3-xl",
    subheadline: "Professional Indoor/Outdoor LED Video Panels",
    score: 0,
    color: "#13a086",
    logo: "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/6419ff447f0111d21b75a915_logo-icon.png",
    panels: [
      {
        name: "ICON™ IP3 XL",
        pixelPitch: 3.9,
        ledConfig: "SMD 1921",
        environment: "Outdoor",
        refreshRate: "≥3,840 Hz",
        brightness: "4,500 NITS",
        dimensions: "500x1000 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/6418abcb3f6100d8b6abd37a_products%252F3196%252Fimages%252FICON-IP3XL-render_1440x1440.webp",
      },
      {
        name: "ICON™ IP3",
        pixelPitch: 3.9,
        ledConfig: "SMD 1921",
        environment: "Outdoor",
        refreshRate: "≥3,840 Hz",
        brightness: "4,500 NITS",
        dimensions: "500x500 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/6418abb5571512bd6df3763a_products%252F3195%252Fimages%252F0003s_0002_IRiS-ICON-render-ip3_1440x1440.webp",
      },
      {
        name: "ICON™ 2.6",
        pixelPitch: 2.6,
        ledConfig: "SMD 1515",
        environment: "Indoor",
        refreshRate: "≥3,840 Hz",
        brightness: "800 NITS",
        dimensions: "500x500 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/64186a570d52a32ed7e95879_products%252F3187%252Fimages%252FICON-26-featured_1440x1440.webp",
      },
      {
        name: "ICON™ 2.9",
        pixelPitch: 2.9,
        ledConfig: "SMD 1515",
        environment: "Indoor",
        refreshRate: "≥3,840 Hz",
        brightness: "1,000 NITS",
        dimensions: "500x500 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/6418aba95cefafedcdd40612_products%252F3188%252Fimages%252F0003s_0001_IRiS-ICON-render-29_1440x1440.webp",
      },
    ],
  },
  {
    series: "inspire",
    handle: "iris-inspire-1-5",
    subheadline: "Direct View LED Display",
    score: 0,
    color: "#4a58a7",
    logo: "https://global-uploads.webflow.com/6400c151037d7a4efc7e9278/6413408cac88daf28f3747b9_iris_inspire_white%201.png",

    panels: [
      {
        name: "InSpire™ 1.2",
        pixelPitch: 1.2,
        ledConfig: "SMD 1010",
        environment: "Indoor",
        refreshRate: "≥3,840 Hz",
        brightness: "600 NITS",
        dimensions: "600x337.5 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/6418ad525cefaf1aabd4c2b1_IRiS_InSpire_12_Rear_padded_1440x1440.webp",
      },
      {
        name: "InSpire™ 1.5",
        pixelPitch: 1.5,
        ledConfig: "SMD 1212",
        environment: "Indoor",
        refreshRate: "≥3,840 Hz",
        brightness: "600 NITS",
        dimensions: "600x337.5 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/6418ad668aa379bd76945354_IRiS_InSpire_15_Rear_padded_1440x1440.webp",
      },
      {
        name: "InSpire™ 1.8",
        pixelPitch: 1.8,
        ledConfig: "SMD 1415",
        environment: "Indoor",
        refreshRate: "≥3,840 Hz",
        brightness: "800 NITS",
        dimensions: "600x337.5 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/6418ad731a4693099d966ed3_products%252F3232%252Fimages%252FIRiS_InSpire_18_0001_1440x1440.webp",
      },
    ],
  },
  {
    series: "insite",
    handle: "iris-insite-g2-1-8",
    subheadline: "Fine Pixel Video Wall System",
    color: "#f3c429",
    score: 0,
    logo: "https://global-uploads.webflow.com/6400c151037d7a4efc7e9278/641323be42563b4c1f74c594_iris_insite_g2_white%201.png",

    panels: [
      {
        name: "InSite™ G2 1.2",
        pixelPitch: 1.2,
        ledConfig: "SMD 1010",
        environment: "Indoor",
        refreshRate: "≥3,840 Hz",
        brightness: "600 NITS",
        dimensions: "600x337.5 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/6418acdcf0f3449128cc8087_IRiS_InSite_12_0001_1440x1440.webp",
      },
      {
        name: "InSite™ G2 1.5",
        pixelPitch: 1.5,
        ledConfig: "SMD 1212",
        environment: "Indoor",
        refreshRate: "≥3,840 Hz",
        brightness: "600 NITS",
        dimensions: "600x337.5 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/6418ac945715129c06f3d023_IRiS_InSite_15_0001_1440x1440.webp",
      },
      {
        name: "InSite™ G2 1.8",
        pixelPitch: 1.8,
        ledConfig: "SMD 1415",
        environment: "Indoor",
        refreshRate: "≥3,840 Hz",
        brightness: "800 NITS",
        dimensions: "600x337.5 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/6418ac56f2ff9133d783833d_IRiS_InSite_18_0001_1440x1440.webp",
      },
    ],
  },
  {
    series: "insignia",
    handle: "iris-insignia-4-8",
    subheadline: "Engaging Digital Display Solutions",
    score: 0,
    color: "#849a3b",
    logo: "https://global-uploads.webflow.com/6400c151037d7a4efc7e9278/641323871c3302e26fa854cf_iris_insignia_mark_white%201.png",

    panels: [
      {
        name: "InSignia™ Panorama 2.5",
        pixelPitch: 2.5,
        ledConfig: "SMD 2020",
        environment: "Indoor",
        refreshRate: "≥2,880 Hz",
        brightness: "1000 NITS",
        dimensions: "1200x300 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/64149966285b6694a4812b1f_products%252F3218%252Fimages%252Finsignia_panorama_rear_1440x1440.webp",
      },
      {
        name: "InSignia™ Panorama 3.1",
        pixelPitch: 3.1,
        ledConfig: "SMD 2020",
        environment: "Indoor",
        refreshRate: "≥3,840 Hz",
        brightness: "1000 NITS",
        dimensions: "1200x300 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/64149966285b6694a4812b1f_products%252F3218%252Fimages%252Finsignia_panorama_rear_1440x1440.webp",
      },
      {
        name: "InSignia™ Panorama 4.0",
        pixelPitch: 4.0,
        ledConfig: "SMD 2020",
        environment: "Indoor",
        refreshRate: "≥3,840 Hz",
        brightness: "1000 NITS",
        dimensions: "1200x300 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/64149966285b6694a4812b1f_products%252F3218%252Fimages%252Finsignia_panorama_rear_1440x1440.webp",
      },
      {
        name: "InSignia™ IP4",
        pixelPitch: 4.8,
        ledConfig: "SMD 1820",
        environment: "Outdoor",
        refreshRate: "≥1,920 Hz",
        brightness: "5,000 NITS",
        dimensions: "1000x1000 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/64186d923604b310d25fdf3a_products%252F3216%252Fimages%252Finsignia_ip_rear_1440x1440.webp",
      },
      {
        name: "InSignia™ IP7",
        pixelPitch: 7.8,
        ledConfig: "SMD 2727",
        environment: "Outdoor",
        refreshRate: "≥1,920 Hz",
        brightness: "6,000 NITS",
        dimensions: "1000x1000 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/64186d923604b310d25fdf3a_products%252F3216%252Fimages%252Finsignia_ip_rear_1440x1440.webp",
      },
      {
        name: "InSignia™ IP10",
        pixelPitch: 10.4,
        ledConfig: "SMD 2727",
        environment: "Outdoor",
        refreshRate: "≥1,920 Hz",
        brightness: "6,500 NITS",
        dimensions: "1000x1000 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/64186d923604b310d25fdf3a_products%252F3216%252Fimages%252Finsignia_ip_rear_1440x1440.webp",
      },
      {
        name: "InSignia™ IP10",
        pixelPitch: 10.0,
        ledConfig: "SMD 2727",
        environment: "Outdoor",
        refreshRate: "≥1,920 Hz",
        brightness: "7,000 NITS",
        dimensions: "1000x1000 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/64186d923604b310d25fdf3a_products%252F3216%252Fimages%252Finsignia_ip_rear_1440x1440.webp",
      },
      {
        name: "InSignia™ 1.9",
        pixelPitch: 1.9,
        ledConfig: "SMD 1415",
        environment: "Indoor",
        refreshRate: "≥3,840 Hz",
        brightness: "800 NITS",
        dimensions: "500x500 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/641885fcb5e71a1bf4b04d6a_products%252F2979%252Fimages%252F0000_insignia_26_rear_1440x1440.webp",
      },
      {
        name: "InSignia™ 2.6",
        pixelPitch: 2.6,
        ledConfig: "SMD 1415",
        environment: "Indoor",
        refreshRate: "≥3,840 Hz",
        brightness: "800 NITS",
        dimensions: "500x500 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/641885fcb5e71a1bf4b04d6a_products%252F2979%252Fimages%252F0000_insignia_26_rear_1440x1440.webp",
      },
      {
        name: "InSignia™ 2.9",
        pixelPitch: 2.9,
        ledConfig: "SMD 1415",
        environment: "Indoor",
        refreshRate: "≥3,840 Hz",
        brightness: "800 NITS",
        dimensions: "500x500 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/641885fcb5e71a1bf4b04d6a_products%252F2979%252Fimages%252F0000_insignia_26_rear_1440x1440.webp",
      },
      {
        name: "InSignia™ 3.9",
        pixelPitch: 3.9,
        ledConfig: "SMD 2020",
        environment: "Indoor",
        refreshRate: "≥3,840 Hz",
        brightness: "800 NITS",
        dimensions: "500x500 MM",
        image:
          "https://global-uploads.webflow.com/640a4c5b8082635136f7ff9a/641885fcb5e71a1bf4b04d6a_products%252F2979%252Fimages%252F0000_insignia_26_rear_1440x1440.webp",
      },
    ],
  },
];
export const INITIAL_STATE = [
  {
    id: 0,
    selected: null,
  },
  {
    id: 1,
    selected: null,
  },
  {
    id: 2,
    selected: null,
  },
  {
    id: 3,
    selected: null,
  },
  {
    id: 4,
    selected: 1,
  },
  {
    id: 5,
    selected: null,
  },
  {
    id: 6,
    selected: null,
  },
  {
    id: 7,
    selected: null,
  },
];
