import React from "react";

const Input = ({ label, placeholder, value, onChange }) => {
  return (
    <div className="flex flex-wrap mb-5">
      <label className="text-left text-gray-500 pb-3 w-full" htmlFor={label}>
        {label}
      </label>
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full border border-gray-500 p-3"
        placeholder={placeholder}
      />
    </div>
  );
};

export default Input;
